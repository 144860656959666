.navbar {
  padding-left: 15px;
  padding-right: 15px;
  position: fixed;
  height: 75px;
  width: 100%;
  z-index: 5;
}

.nav-link {
  cursor: pointer;
}

@media screen and (max-width: 335px) {
  .logo {
    max-width: 100px;
  }
}
