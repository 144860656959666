.login-inner {
  height: 90vh;
}

.login-form input {
  width: 320px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@media (max-width: 350px) {
  .login-form input {
    max-width: 290px;
  }
}

.error-message {
    color: red;
    font-size: 15px;
    font-weight: 500;
}