body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth;
}

#root {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;
}

.table-wrapper {
  overflow: hidden;
  overflow-x: scroll;
  width: 75%;
}

.table-wrapper .table tbody {
  border-top: none;
}

.table-wrapper .table tr.table-item {
  background-color: rgba(173, 167, 154, 0.1);
}

.table-wrapper .table tr.table-item:nth-child(odd) {
  background-color: white;
}

.table-wrapper::-webkit-scrollbar {
  width: 0px;
}

.table-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px 0;
}

@media (max-width: 775px) {
  .table-wrapper {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .table-pagination {
    justify-content: center;
    align-items: center;
    width: 75%;
    padding-right: 0;
  }
}
