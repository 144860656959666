.filter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.filter-datepicker {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.filter-datepicker label {
  align-self: flex-start;
}

.filter-datepicker-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.filter-button {
  margin: 10px 0;
  color: #fff;
}
