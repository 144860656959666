.revenue-table {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.table-inner {
  display: flex;
  justify-content: center;
}
.table-item.unpaid > .total {
  text-decoration: line-through;
  text-decoration-color: red;
}

.table-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.table-pagination button {
  margin: 0 10px;
}

.table-pagination span {
  font-weight: 500;
  font-size: 20px;
}

.error {
  color: #000;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
