.footer {
  background-color: #f8f9fa;
  padding: 20px;
}

.footer-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}
